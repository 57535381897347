const Partners = () => {
  return (
    <div className="dark:bg-jacarta-900">
      <div className="container">
        <div className="py-8 text-center">
          <h2 className="text-xl font-bold text-jacarta-700 dark:text-white mb-4">
            Compra tu NFT 
          </h2>
          <p className="text-sm text-jacarta-700 dark:text-jacarta-200 mb-6">
            Con Digital Wines Club, puedes adquirir tu NFT de manera fácil, incluso si no tienes experiencia en blockchain. A través de nuestro servicio, te ayudamos a generar una wallet digital y realizar el mint de tu NFT, para que puedas formar parte de nuestra exclusiva comunidad de amantes del vino.
          </p>

          <h3 className="text-xl font-semibold text-jacarta-500 dark:text-white mb-4">
            Proceso de Compra
          </h3>
          <ul className="list-decimal list-inside text-sm text-jacarta-700 dark:text-jacarta-100 space-y-3">
            <li>Realiza tu pago de manera segura mediante tarjeta de crédito o transferencia bancaria.</li>
            <li>Generaremos una wallet digital a tu nombre y depositaremos el equivalente en criptomoneda.</li>
            <li>Realizaremos el mint de tu NFT directamente en tu wallet.</li>
            <li>Recibirás un correo electrónico con las credenciales de acceso a tu wallet y una guía para utilizarla.</li>
            <li>Si deseas recibir tus datos de acceso a través de otro medio comunicate con nosotros a través de las redes sociales o al mail contacto@digitalwinesclub.com</li>
          </ul>
          <a
            href="https://mpago.la/1bfZY9z"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-accent hover:bg-accent-dark text-white font-semibold py-3 px-8 rounded-full mt-8 inline-block"
          >
            Realizar Pago
          </a>

          <h3 className="text-l font-semibold text-jacarta-700 dark:text-white mt-8 mb-4">
            Importante: Exención de Responsabilidad
          </h3>
          <p className="text-sm text-jacarta-700 dark:text-jacarta-200 mb-4">
            Una vez que el NFT se ha transferido a tu wallet, eres el único propietario de la misma y de sus claves privadas. Digital Wines Club no guarda una copia de tus claves privadas y no puede recuperar el acceso en caso de pérdida.
          </p>
          <p className="text-sm text-jacarta-700 dark:text-jacarta-200 mb-4">
            Es tu responsabilidad asegurar y administrar las credenciales de tu wallet y los activos dentro de ella. Digital Wines Club no se hace responsable por la pérdida, extravío o acceso no autorizado a tu wallet una vez transferidos los datos.
          </p>
          <p className="text-sm text-jacarta-700 dark:text-jacarta-200 mb-6">
            Al realizar tu compra, aceptas estos términos y entiendes que la posesión y administración de la wallet es completamente tu responsabilidad.
          </p>

{/*           <p className="text-sm text-jacarta-700 dark:text-jacarta-200 mb-6">
            ¿Tienes dudas? Consulta nuestra{" "}
            <Link href="/guia-inicio-rapido">
              <a className="text-accent underline">Guía de Inicio Rápido</a>
            </Link>{" "}
            para aprender a usar tu wallet y asegurar tus activos de manera correcta.
          </p> */}
          
        </div>
      </div>
    </div>
  );
};

export default Partners;
